<template>
    <el-dialog :visible="visible" width="60%" @close="handleClose" class="dialog" :title="title">
        <el-tabs v-model="activeName">
            <el-tab-pane label="评估报告" name="first">
                <el-form ref="form" :model="form" label-width="110px" size="mini">
                    <el-empty description="暂无内容"></el-empty>
                    <!--                    <el-form-item label="用户账号：" prop="username">-->
<!--                        <el-input v-model="form.username" />-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="用户名称：" prop="nickname">-->
<!--                        <el-input v-model="form.nickname" />-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="用户密码：" prop="password" v-if="!form.id">-->
<!--                        <el-input v-model="form.password" show-password />-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="备注：" prop="remark">-->
<!--                        <el-input v-model="form.remark" />-->
<!--                    </el-form-item>-->

                    <el-form-item style="padding-top: 40px;text-align: right">
                        <el-button type="primary" @click="onSubmit">确 定</el-button>
                        <el-button @click="handleClose">取 消</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="量表记录" name="second">
                <el-empty description="暂无内容"></el-empty>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "心理评估"
            },
        },
        data() {
            return {
                activeName:'first',
            }
        },
        methods: {
            getData() {
                this.dialogVisible = false
            },
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }

                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
