const data = {
    table: {
        columns: [
            {title: '名称', field: 'name', width: ''},
            {title: '性别', field: 'gender', width: ''},
            {title: '年龄', field: 'age', width: ''},
            {title: '手机', field: 'phone', width: ''},
            {title: '身份证', field: 'sfz', width: '160'},
            {title: '状态', field: 'status', width: ''},
            {title: '评估时间', field: 'date', width: ''},
            {title: '评估医生', field: 'doctor', width: ''},
            {title: '审核医生', field: 'audit', width: ''},
            {title: '评估历史', field: 'history', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
    form: {
        "ys":[
            {
                key: "",
                title: "1.常吃早餐：*",
                remark: "",
                option: [
                    {
                        lable: "没有",
                        value: "没有",
                    },
                    {
                        lable: "1~2天",
                        value: "1~2天",
                    },
                    {
                        lable: "3~4天",
                        value: "3~4天",
                    },
                    {
                        lable: "5~6天",
                        value: "5~6天",
                    },
                    {
                        lable: "7天",
                        value: "7天",
                    }
                ],

            },
            {
                title: "2.常吃夜宵：*",
                remark: "",
                option: [
                    {
                        lable: "没有",
                        value: "没有",
                    },
                    {
                        lable: "1~2天",
                        value: "1~2天",
                    },
                    {
                        lable: "3~4天",
                        value: "3~4天",
                    },
                    {
                        lable: "5~6天",
                        value: "5~6天",
                    },
                    {
                        lable: "7天",
                        value: "7天",
                    }
                ],
            },
            {
                title: "3.您目前饮食的不良习惯：*",
                remark: "",
                type:'checkbox',
                option: [
                    {
                        lable: "吃饭时喝水",
                        value: "吃饭时喝水",
                    },
                    {
                        lable: "吃饭过快",
                        value: "吃饭过快",
                    },
                    {
                        lable: "吃得过饱",
                        value: "吃得过饱",
                    },
                    {
                        lable: "挑食偏食",
                        value: "挑食偏食",
                    }
                ],
            }, {
                title: "4.您的饮食口味：*",
                remark: "",
                type:'checkbox',
                option: [
                    {
                        lable: "清淡",
                        value: "清淡",
                    },
                    {
                        lable: "咸",
                        value: "咸",
                    },
                    {
                        lable: "甜",
                        value: "甜",
                    },
                    {
                        lable: "生",
                        value: "生",
                    },
                    {
                        lable: "冷",
                        value: "冷",
                    },
                    {
                        lable: "硬",
                        value: "硬",
                    },
                    {
                        lable: "热烫",
                        value: "热烫",
                    },
                    {
                        lable: "高油脂",
                        value: "高油脂",
                    },
                    {
                        lable: "腌熏",
                        value: "腌熏",
                    },
                    {
                        lable: "辛辣",
                        value: "辛辣",
                    }
                ],
            }, {
                title: "5.您的饮食偏好：*",
                remark: "",
                type:'checkbox',
                option: [
                    {
                        lable: "油炸食品",
                        value: "油炸食品",
                    },
                    {
                        lable: "甜点",
                        value: "甜点",
                    },
                    {
                        lable: "吃零食(适量坚果除外)",
                        value: "吃零食(适量坚果除外)",
                    },
                    {
                        lable: "经常吃快餐",
                        value: "经常吃快餐",
                    },
                    {
                        lable: "喝粥(>1次/天)",
                        value: "喝粥(>1次/天)",
                    }
                ],
            },
        ],
        "sr":[
            {
                key: "",
                title: "1.米面类每天食量：     *",
                remark: "一碗指2两",
                option: [
                    {
                        lable: "＜1碗",
                        value: "＜1碗",
                    },
                    {
                        lable: "1~＜2碗",
                        value: "1~＜2碗",
                    },
                    {
                        lable: "2~＜4碗",
                        value: "2~＜4碗",
                    },
                    {
                        lable: "4~＜6碗",
                        value: "4~＜6碗",
                    },
                    {
                        lable: "≥6碗",
                        value: "≥6碗",
                    }
                ],
            },
            {
                title: "2.肉类及肉制品每天食量：*",
                remark: "",
                option: [
                    {
                        lable: "不吃",
                        value: "不吃",
                    },
                    {
                        lable: "＜1两",
                        value: "＜1两",
                    },
                    {
                        lable: "1~＜2两",
                        value: "1~＜2两",
                    },
                    {
                        lable: "2~＜5两",
                        value: "2~＜5两",
                    },
                    {
                        lable: "≥5两",
                        value: "≥5两",
                    }
                ],
            },
            {
                title: "3.鱼类及水产类每天食量：*",
                remark: "",
                option: [
                    {
                        lable: "不吃",
                        value: "不吃",
                    },
                    {
                        lable: "＜1两",
                        value: "＜1两",
                    },
                    {
                        lable: "1~＜2两",
                        value: "1~＜2两",
                    },
                    {
                        lable: "2~＜5两",
                        value: "2~＜5两",
                    },
                    {
                        lable: "≥5两",
                        value: "≥5两",
                    }
                ],
            }, {
                title: "4.蛋类及蛋制品每天食量：*",
                remark: "一个指50g",
                option: [
                    {
                        lable: "不吃",
                        value: "不吃",
                    },
                    {
                        lable: "＜0.5个",
                        value: "＜0.5个",
                    },
                    {
                        lable: "0.5~＜1个",
                        value: "0.5~＜1个",
                    },
                    {
                        lable: "1~＜2个",
                        value: "1~＜2个",
                    },
                    {
                        lable: "≥2个",
                        value: "≥2个",
                    }
                ],
            }, {
                title: "5.奶类及奶制品每天食量：*",
                remark: "一杯指200ml",
                option: [
                    {
                        lable: "不吃",
                        value: "不吃",
                    },
                    {
                        lable: "＜1杯",
                        value: "＜1杯",
                    },
                    {
                        lable: "1~＜2杯",
                        value: "1~＜2杯",
                    },
                    {
                        lable: "2~＜3杯",
                        value: "2~＜3杯",
                    },
                    {
                        lable: "≥3杯",
                        value: "≥3杯",
                    }
                ],
            },{
                title: "6.大豆及豆制品每天食量：*",
                remark: "",
                option: [
                    {
                        lable: "不吃",
                        value: "不吃",
                    },
                    {
                        lable: "＜0.5两",
                        value: "＜0.5两",
                    },
                    {
                        lable: "0.5~＜1两",
                        value: "0.5~＜1两",
                    },
                    {
                        lable: "1~＜2两",
                        value: "1~＜2两",
                    },
                    {
                        lable: "≥2两",
                        value: "≥2两",
                    }
                ],
            },{
                title: "7.新鲜蔬菜每天食量：*",
                remark: "",
                option: [
                    {
                        lable: "＜2两",
                        value: "＜2两",
                    },
                    {
                        lable: "2~＜6两",
                        value: "2~＜6两",
                    },
                    {
                        lable: "6~＜10两",
                        value: "6~＜10两",
                    },
                    {
                        lable: "10~＜15两",
                        value: "10~＜15两",
                    },
                    {
                        lable: "≥15两",
                        value: "≥15两",
                    }
                ],
            },{
                title: "8.新鲜水果每天食量：*",
                remark: "",
                option: [
                    {
                        lable: "＜1两",
                        value: "＜1两",
                    },
                    {
                        lable: "1~＜4两",
                        value: "1~＜4两",
                    },
                    {
                        lable: "4~＜8两",
                        value: "4~＜8两",
                    },
                    {
                        lable: "4~＜8两",
                        value: "4~＜8两",
                    },
                    {
                        lable: "≥12两",
                        value: "≥12两",
                    }
                ],
            },{
                title: "9.平均日饮水摄入量：*",
                remark: "一杯指200ml",
                option: [
                    {
                        lable: "＜3杯",
                        value: "＜3杯",
                    },
                    {
                        lable: "3~＜6杯",
                        value: "3~＜6杯",
                    },
                    {
                        lable: "6~＜9杯",
                        value: "6~＜9杯",
                    },
                    {
                        lable: "9~＜12杯",
                        value: "9~＜12杯",
                    },
                    {
                        lable: "≥12杯",
                        value: "≥12杯",
                    }
                ],
            },
        ],
        "yd":[
            {
                key: "",
                title: "1.平均每天工作时间： * ",
                remark: "",
                option: [
                    {
                        lable: "没有",
                        value: "没有",
                    },
                    {
                        lable: "1~2小时",
                        value: "1~2小时",
                    },
                    {
                        lable: "2~5小时",
                        value: "2~5小时",
                    },
                    {
                        lable: "5~8小时",
                        value: "5~8小时",
                    },
                    {
                        lable: "≥8小时",
                        value: "≥8小时",
                    }
                ],
            },
            {
                title: "2.平均每天静坐时间：*",
                remark: "",
                option: [
                    {
                        lable: "没有",
                        value: "没有",
                    },
                    {
                        lable: "1~2小时",
                        value: "1~2小时",
                    },
                    {
                        lable: "2~5小时",
                        value: "2~5小时",
                    },
                    {
                        lable: "5~8小时",
                        value: "5~8小时",
                    },
                    {
                        lable: "≥8小时",
                        value: "≥8小时",
                    }
                ],
            },
            {
                title: "3.每周运动锻炼情况：*",
                remark: "",
                option: [
                    {
                        lable: "从不",
                        value: "从不",
                    },
                    {
                        lable: "偶尔（1~2次/周）",
                        value: "偶尔（1~2次/周）",
                    },
                    {
                        lable: "经常（3~5次/周）",
                        value: "经常（3~5次/周）",
                    },
                    {
                        lable: "总是（＞5次/周）",
                        value: "总是（＞5次/周）",
                    }
                ],
            }, {
                title: "4.每次运动锻炼时间：*",
                remark: "",
                option: [
                    {
                        lable: "＜10分钟",
                        value: "＜10分钟",
                    },
                    {
                        lable: "10~＜30分钟",
                        value: "10~＜30分钟",
                    },
                    {
                        lable: "30~＜60分钟",
                        value: "30~＜60分钟",
                    },
                    {
                        lable: "1~＜2小时",
                        value: "1~＜2小时",
                    },
                    {
                        lable: "≥2小时",
                        value: "≥2小时",
                    }
                ],
            }, {
                title: "5.一般锻炼的强度：  *",
                remark: "",
                option: [
                    {
                        lable: "极轻度运动",
                        value: "极轻度运动",
                    },
                    {
                        lable: "轻度运动",
                        value: "轻度运动",
                    },
                    {
                        lable: "中度运动",
                        value: "中度运动",
                    },
                    {
                        lable: "重度运动",
                        value: "重度运动",
                    },
                    {
                        lable: "极重度运动",
                        value: "极重度运动",
                    }
                ],
            },
        ],
        "xy":[
            {
                key: "",
                title: "1.吸烟状态：*",
                remark: "若“从不”则不需填下两题",
                option: [
                    {
                        lable: "从不",
                        value: "从不",
                    },
                    {
                        lable: "偶尔",
                        value: "偶尔",
                    },
                    {
                        lable: "戒烟",
                        value: "戒烟",
                    },
                    {
                        lable: "经常",
                        value: "经常",
                    }
                ],

            },
            {
                title: "2.平均每天几支：*",
                remark: "折算成香烟",
                option: [
                    {
                        lable: "<5支",
                        value: "<5支 ",
                    },
                    {
                        lable: "5~<15支",
                        value: "5~<15支",
                    },
                    {
                        lable: "15~<25支",
                        value: "15~<25支",
                    },
                    {
                        lable: "25~<40支",
                        value: "25~<40支",
                    },
                    {
                        lable: "≥40支",
                        value: "≥40支",
                    }
                ],
            },
           {
                title: "3.您总共吸烟的年数是：*",
                remark: "",
                option: [
                    {
                        lable: "＜5年",
                        value: "＜5年",
                    },
                    {
                        lable: "5~＜15年",
                        value: "5~＜15年",
                    },
                    {
                        lable: "15~＜25年",
                        value: "15~＜25年",
                    },
                    {
                        lable: "25~＜40年",
                        value: "25~＜40年",
                    },
                    {
                        lable: "≥40年",
                        value: "≥40年",
                    }
                ],
           },
            {
                title: "4.被动吸烟：",
                remark: "指“二手烟”",
                option: [
                    {
                        lable: "没有",
                        value: "没有",
                    },
                    {
                        lable: "1~2天 ",
                        value: "1~2天",
                    },
                    {
                        lable: "3~4天",
                        value: "3~4天",
                    },
                    {
                        lable: "5~6天",
                        value: "5~6天",
                    },
                    {
                        lable: "7天",
                        value: "7天",
                    }
                ],
            },
        ],
        "yj":[
            {
                key: "",
                title: "1.当前饮酒情况：*",
                remark: "若“从不”则不需填下三题",
                option: [
                    {
                        lable: "从不",
                        value: "从不",
                    },
                    {
                        lable: "偶尔",
                        value: "偶尔",
                    },
                    {
                        lable: "戒酒",
                        value: "戒酒",
                    },
                    {
                        lable: "经常",
                        value: "经常",
                    }
                ],

            },
            {
                title: "2.饮酒的主要类型：*",
                remark: "",
                option: [
                    {
                        lable: "白酒 ",
                        value: "白酒 ",
                    },
                    {
                        lable: "黄酒",
                        value: "黄酒",
                    },
                    {
                        lable: "红酒",
                        value: "红酒",
                    },
                    {
                        lable: "啤酒",
                        value: "啤酒",
                    },
                    {
                        lable: "其他",
                        value: "其他",
                    }
                ],
            },
            {
                title: "3.平均每天饮酒的两数：*",
                remark: "折算成白酒",
                option: [
                    {
                        lable: "＜2两 ",
                        value: "＜2两 ",
                    },
                    {
                        lable: "2~＜4两 ",
                        value: "2~＜4两 ",
                    },
                    {
                        lable: "4~＜6两",
                        value: "4~＜6两",
                    },
                    {
                        lable: "6~＜8两",
                        value: "6~＜8两",
                    },
                    {
                        lable: "≥8两",
                        value: "≥8两",
                    }
                ],
            }, {
                title: "4.总共饮酒几年：*",
                remark: "",
                option: [
                    {
                        lable: "＜5年",
                        value: "＜5年",
                    },
                    {
                        lable: "5~＜15年",
                        value: "5~＜15年",
                    },
                    {
                        lable: "15~＜25年",
                        value: "15~＜25年",
                    },
                    {
                        lable: "25~＜40年",
                        value: "25~＜40年",
                    },
                    {
                        lable: "≥40年",
                        value: "≥40年",
                    }
                ],
            }
        ],
        "qita":[
                {
                    key: "",
                    title: "1.遭受重大意外困扰：*",
                    remark: "如重大经济损失、亲属亡故或自然灾害等",
                    option: [
                        {
                            lable: "是",
                            value: "是",
                        },
                        {
                            lable: "否",
                            value: "否",
                        }
                    ],

                },
                {
                    title: "2.情绪对工作或生活的影响：*",
                    remark: "",
                    option: [
                        {
                            lable: "几乎没有 ",
                            value: "几乎没有 ",
                        },
                        {
                            lable: "有一点",
                            value: "有一点",
                        },
                        {
                            lable: "较明显",
                            value: "较明显",
                        },
                        {
                            lable: "很大",
                            value: "很大",
                        }
                    ],
                },
                {
                    title: "3.感到精神压力：*",
                    remark: "",
                    option: [
                        {
                            lable: "几乎没有 ",
                            value: "几乎没有 ",
                        },
                        {
                            lable: "有一点",
                            value: "有一点",
                        },
                        {
                            lable: "较明显",
                            value: "较明显",
                        },
                        {
                            lable: "很大",
                            value: "很大",
                        }
                    ],
                }, {
                title: "4.睡眠充足程度：*",
                remark: "",
                option: [
                    {
                        lable: "充足",
                        value: "充足",
                    },
                    {
                        lable: "一般",
                        value: "一般",
                    },
                    {
                        lable: "不足",
                        value: "不足",
                    },
                    {
                        lable: "严重不足",
                        value: "严重不足",
                    }
                ],
            }, {
                title: "5.皮质激素服用：*",
                remark: "",
                option: [
                    {
                        lable: "没有",
                        value: "没有",
                    },
                    {
                        lable: "<3月",
                        value: "<3月",
                    },
                    {
                        lable: "3~12月",
                        value: "3~12月",
                    },
                    {
                        lable: "1~3年",
                        value: "1~3年",
                    },
                    {
                        lable: "3年以上",
                        value: "3年以上",
                    }
                ],
            },
            ]
    }
}

export default data
