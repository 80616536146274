<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <!--                <el-button type="primary" size="mini" @click="handleAdd">添加标签</el-button>-->
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="心理评估"/>
        </div>
<!--        <el-row style="height: 100%;display: flex;">-->
<!--            <el-col :span="19" class="page">-->
                <div class="search">
                    <el-form :inline="true" :model="search" size="small" label-width="70px">
<!--                        <el-form-item label="工作区">-->
<!--                            <el-select v-model="search.wpName" placeholder="请选择" style="width:100%" clearable>-->
<!--                                <el-option label="全部" value="全部"></el-option>-->
<!--                                <el-option :label="item.name" :value="item.name" v-for="(item,index) in gzqList"-->
<!--                                           :key="index"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="工作单位" label-width="70px">
                            <cascader-company-name v-model="search.companyName"></cascader-company-name>
                        </el-form-item>
<!--                        <el-form-item label="标签分组">-->
<!--                            <el-select v-model="search.KHBQ" placeholder="请选择" style="width:100%"-->
<!--                                       filterable allow-create default-first-option clearable>-->
<!--                                <el-option label="全部" value="全部"></el-option>-->
<!--                                <el-option :label="item.name" :value="item.name" v-for="(item,index) in tagList"-->
<!--                                           :key="index"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="搜索" label-width="40px">
                            <el-input placeholder="请输入内容" v-model="search.value" class="input-with-select" clearable>
                                <el-select v-model="search.key" slot="prepend" placeholder="请选择" style="width:60px">
                                    <el-option label="名称" value="name"></el-option>
                                    <el-option label="身份证" value="idCard"></el-option>
                                    <el-option label="手机号" value="mobile"></el-option>
                                    <el-option label="单位" value="companyName"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                            <el-form-item label="报告筛选">
                                <el-select v-model="search.reportType" placeholder="请选择" style="width:180px" clearable>
                                    <el-option label="体检报告" value="1"></el-option>
                                    <el-option label="小屋报告" value="2"></el-option>
                                    <el-option label="手工体检表" value="3"></el-option>
                                    <el-option label="文件上传" value="4"></el-option>
                                    <el-option label="体检提醒" value="5"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="-" label-width="8px">
                                <el-date-picker v-model="search.reportTime" type="daterange" range-separator="至" clearable
                                                style="width: 300px"
                                                :disabled="search.reportType?false:true"
                                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="风险人群" label-width="70px">
                                <el-select v-model="search.risklevel" placeholder="请选择" style="width:100%" clearable>
                                    <el-option label="健康人群" value="健康人群"></el-option>
                                    <el-option label="风险人群" value="风险人群"></el-option>
                                    <el-option label="异常人群" value="异常人群"></el-option>
                                    <el-option label="慢病人群" value="慢病人群"></el-option>
                                    <el-option label="大病人群" value="大病人群"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="人群等级" label-width="70px">
                                <el-select v-model="search.level" placeholder="请选择" style="width:100%" filterable allow-create
                                           default-first-option clearable>
                                    <el-option label="全部" value="全部"></el-option>
                                    <el-option :label="item.name" :value="item.name" v-for="(item, index) in tagList"
                                               :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="指标等级" label-width="70px">
                                <el-cascader :options="dbList" :props="defaultProps" collapse-tags clearable
                                             v-model="search.risklevelList"></el-cascader>
                            </el-form-item>
<!--                            <el-form-item label="-" label-width="8px">-->
<!--                                <el-select v-model="search.reportType" placeholder="请选择" style="width:180px" clearable>-->
<!--                                    <el-option label="全部" value="全部"></el-option>-->
<!--                                    <el-option label="有" value="1"></el-option>-->
<!--                                    <el-option label="无" value="0"></el-option>-->
<!--                                </el-select>-->
<!--                            </el-form-item>-->
                            <el-form-item>
                                <el-button type="primary" plain @click="getData">查询</el-button>
                                <el-button type="" plain @click="reset">重置</el-button>
                            </el-form-item>
                    </el-form>
                </div>
                <div class="search" style="background-color: #fff">
                    <div class="btns">
                        <el-button type="primary" size="mini" @click="handleAdd" :disabled="idList.length?false:true">批量干预</el-button>
                        <el-button type="primary" size="mini" @click="handleAdd" :disabled="idList.length?false:true">发送自评量表</el-button>
                        <el-button type="primary" size="mini" @click="handleAdd" :disabled="idList.length?false:true">批量催办</el-button>
                        <el-button type="primary" size="mini" @click="handleAdd" :disabled="idList.length?false:true">开具个人报告</el-button>
                    </div>
                </div>
                <div class="main-content">
                    <div class="table-content">
                        <el-table :data="table.data" stripe size="mini" border v-loading="loading" width="100%"
                                  height="100%"
                                  @selection-change="handleSelectionChange">
                            <el-table-column
                                    type="selection"
                                    width="55">
                            </el-table-column>
                            <el-table-column type="index" label="序号" align="center"
                                             :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                             width="55"/>
                            <el-table-column align="center" label="基本信息" width="160">
                                <template slot-scope="scope">
                                    <div>
                                        <span>{{ scope.row.name }}</span>
                                        <span v-if="scope.row.sex=='2'"> 女 </span>
                                        <span v-if="scope.row.sex=='1'"> 男 </span>
                                        <span v-if="scope.row.age">{{scope.row.age }} 岁</span>
                                    </div>
                                    <div><small> {{ maskIdCardRegex(scope.row.idCard) }}</small>
                                        <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                                           @click="toggleIdCard(scope.$index)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="客户标签" width="100">
                                <template slot-scope="scope">
                                    <popover-JCXM :tagList="scope.row"/>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="联系方式" width="200">
                                <template slot-scope="scope">
                                    <div>
                                        <span v-if="scope.row.entOrgNameTwo">{{scope.row.entOrgNameTwo}}</span>
                                        <span v-if="scope.row.entOrgNameThree"> | {{scope.row.entOrgNameThree}}</span>
                                        <span v-if="scope.row.entOrgNameFour"> | {{scope.row.entOrgNameFour}}</span>
                                        <span v-if="scope.row.entOrgNameFive"> | {{scope.row.entOrgNameFive}}</span>
                                    </div>
                                    <div>{{ scope.row.mobile }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="工作区" width="120">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.wpName }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="激活状态" width="" prop="activeStatus">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.activeStatus" type="success">已激活</el-tag>
                                    <el-tag v-else type="info">未激活</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="抑郁" width="120">
                                <template slot-scope="scope">
                                    <div>-/-</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="焦虑" width="120">
                                <template slot-scope="scope">
                                    <div>-/-</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="睡眠" width="120">
                                <template slot-scope="scope">
                                    <div>-/-</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="职业倦怠" width="120">
                                <template slot-scope="scope">
                                    <div>-/-</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="评估报告" width="120">
                                <template slot-scope="scope">
                                    <div>-/-</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="最新报告时间" width="120">
                                <template slot-scope="scope">
                                    <div>-/-</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="自评量表" width="120">
                                <template slot-scope="scope">
                                    <div>-/-</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" width="200" label="操作" fixed="right">
                                <template slot-scope="scope">
                                    <el-button type="text" size="mini" @click="handleRowpg(scope.row)">进行评估
                                    </el-button>
                                    <el-button type="text" size="mini" @click="handleRowChange(scope.row)">健康档案
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false"
                           @update="getData"></data-form>
                <div class="pager" v-if="table.total > table.pageSize">
                    <el-pagination layout="total, prev, pager, next, sizes"
                                   :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                                   :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                                   @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
                </div>
<!--            </el-col>-->
<!--            <el-col :span="5">-->
<!--                <div style="padding-left: 20px;" class="right-search">-->
<!--                    <el-form :model="search" size="small" label-position="top">-->
<!--                        <el-form-item label="标签筛选">-->
<!--                            <el-radio-group v-model="search.tag">-->
<!--                                <el-radio label="1">体检</el-radio>-->
<!--                                <el-radio label="2">评估</el-radio>-->
<!--                                <el-radio label="3">个人</el-radio>-->
<!--                            </el-radio-group>-->
<!--                        </el-form-item>-->
<!--                        <div v-if="search.tag=='1'">-->
<!--                            <el-form-item label="体检时间">-->
<!--                                <el-date-picker-->
<!--                                        style="width: 100%;"-->
<!--                                        v-model="search.time"-->
<!--                                        type="daterange"-->
<!--                                        range-separator="至"-->
<!--                                        start-placeholder="开始日期"-->
<!--                                        end-placeholder="结束日期">-->
<!--                                </el-date-picker>-->
<!--                            </el-form-item>-->
<!--                            <el-form-item label="">-->
<!--                                <el-input v-model="search.kjz" placeholder="请输入关键字过滤"></el-input>-->
<!--                            </el-form-item>-->
<!--                        </div>-->
<!--                        <div v-if="search.tag=='2'">-->
<!--                            <el-form-item label="">-->
<!--                                <el-select v-model="search.type" placeholder="" style="width:100%">-->
<!--                                    <el-option label="全部" value="0"></el-option>-->
<!--                                    <el-option label="HRA健康风险评估3.0" value="1"></el-option>-->
<!--                                    <el-option label="慢病健康风险评估" value="2"></el-option>-->
<!--                                    <el-option label="HRA健康风险评估2.0" value="3"></el-option>-->
<!--                                    <el-option label="心理健康评估" value="4"></el-option>-->
<!--                                </el-select>-->
<!--                            </el-form-item>-->
<!--                            <el-form-item label="">-->
<!--                                <el-input v-model="search.kjz" placeholder="请输入关键字过滤"-->
<!--                                          type="textarea"-->
<!--                                          maxlength="5"-->
<!--                                          show-word-limit-->
<!--                                ></el-input>-->
<!--                            </el-form-item>-->
<!--                            <el-tree style="height: calc( 100vh - 350px );overflow: auto;"-->
<!--                                     :data="pgList"-->
<!--                                     show-checkbox-->
<!--                                     node-key="id"-->
<!--                                     :props="defaultProps">-->
<!--                            </el-tree>-->
<!--                        </div>-->
<!--                        <div v-if="search.tag=='3'">-->
<!--                            <el-form-item label="">-->
<!--                                <el-input v-model="search.grtag" placeholder="请输入关键字过滤" type="textarea"-->
<!--                                          maxlength="5"-->
<!--                                          show-word-limit></el-input>-->
<!--                            </el-form-item>-->
<!--                            <el-checkbox-group v-model="search.grTagList"-->
<!--                                               style="height: calc( 100vh - 300px );overflow: auto;">-->
<!--                                <div v-for="(item,index) in grList" :key="index" style="padding: 5px">-->
<!--                                    <el-checkbox :label="item.name"></el-checkbox>-->
<!--                                </div>-->
<!--                            </el-checkbox-group>-->
<!--                        </div>-->
<!--                    </el-form>-->
<!--                </div>-->
<!--            </el-col>-->
<!--        </el-row>-->
    </div>
</template>

<script>
    import data from './data'
    import formatter from "../../data/formatter"
    import {dbList, dwList, grList, pgList} from "../../data/utlisData"
    import dataForm from "./dataForm";

    const makeInitSearch = () => {
        return {
            companyName: "",
            wpName: "",
            key: "name",
            value: "",
            report: "",
            createdat: [],
            tag: "1",
            grTagList: [],
            reportTime:[],
            type: "0"
        }
    }


    export default {
        name: 'phr',
        components: {
            dataForm,
        },
        data() {
            return {
                gzqList: [],
                dbList: dbList,
                defaultProps: {
                    multiple: true,
                    children: 'tags',
                    label: 'name',
                    value: 'value',
                    emitPath: false
                },
                isFullIdCard: {},
                tagList: [
                    {
                        id: 3,
                        name: "三级"
                    },
                    {
                        id: 2,
                        name: "二级"
                    },
                    {
                        id: 1,
                        name: "一级"
                    },
                ],
                form: {
                    idList: [],
                },
                dialogVisible: false,
                formatter: formatter,
                dwList: dwList,
                grList: grList,
                pgList: pgList,
                loading: false,
                search: makeInitSearch(),
                table: data.table,
                idList: []
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if(from.path.indexOf("/main/phr/detail/")>-1){
                    vm.handlePopState()
                }
            });
        },
        methods: {
            handlePopState() {
                const savedConditions = localStorage.getItem('searchConditions');
                const black = localStorage.getItem('back');
                if (savedConditions && black) {
                    this.search = JSON.parse(savedConditions);
                }
            },
            handleRowpg(){
                this.dialogVisible = true
            },
            maskIdCardRegex(idCard) {
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            toggleIdCard(index) {
                // 切换显示状态
                this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
            },
            handleAdd() {
                this.$message({
                    message: '开发中',
                    type: 'warning'
                });
                return
            },
            reset() {
                this.search = makeInitSearch()
                setTimeout(() => {
                    this.getData()
                    localStorage.removeItem('searchConditions');
                    localStorage.removeItem('back');
                }, 0);
            },
            handleRowChange(row) {
                this.$router.push({path: `/main/phr/detail/${row.id}`})
            },
            handleSelectionChange(val) {
                var that = this
                that.idList = val
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                this.search.page = this.table.page
                this.search.pageSize = this.table.pageSize
                const resp = await this.$http.post(`/phr`, this.search)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                    localStorage.setItem('searchConditions',JSON.stringify(this.search));
                }
                this.loading = false
            },
            async getUserOwn() {
                const resp = await this.$http.get(`/userown?page=${this.table.page}&pageSize=500`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.gzqList = resp.data.data
                }
            }
        },
        mounted() {
            this.getUserOwn()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px 0!important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
